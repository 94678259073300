<template>
  <div class="py-6 px-6">
    <div>
      <nav class="sm:hidden" aria-label="Back">
        <a
          href="#"
          class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Back
        </a>
      </nav>
      <nav class="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex">
              <router-link
                :to="'/settings'"
                class="text-sm font-medium text-gray-500 hover:text-gray-700"
                >Settings</router-link
              >
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="#"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >Subscriptions</a
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="px-5 py-0">
    <div class="text-medium leading-6 font-medium text-gray-900 mb-3">
      Upcoming Stripe Invoice
    </div>

    <div class="text-sm leading-6 font-small text-gray-700 mb-3">
      This is a preview of the invoice that will be billed on
      {{ convertToDate(stripeUpcomingInvoice.period_end) }}. It may change if
      the subscription is updated.
    </div>

    <hr />

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-white">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    QTY
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Unit Price
                  </th>
                  <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th> -->

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="bg-white">
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 uppercase"
                  >
                    {{ convertToDate(stripeUpcomingInvoice.period_start) }} -
                    {{ convertToDate(stripeUpcomingInvoice.period_end) }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="(
                    line_item, line_item_idx
                  ) in stripeUpcomingInvoiceLineItems"
                  :key="line_item.id"
                  :class="line_item_idx % 2 === 0 ? 'bg-white' : 'bg-white'"
                >
                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    <!-- {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.total / 100 }} -->
                    {{ line_item.description }}
                  </td>

                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    {{ line_item.quantity }}
                  </td>
                  <td
                    class="px-6 py-2 whitespace-nowrap text-sm font-small text-gray-900"
                  >
                    {{ getCurrencySymbol(line_item.price.currency) }}
                    {{ line_item.price.unit_amount / 100 }}
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(line_item.currency) }}
                    {{ line_item.amount / 100 }}
                  </td>
                </tr>
                <tr>
                  <td><hr /></td>
                </tr>
                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td
                    class="px-6 py-1 pt-5 whitespace-nowrap text-sm text-gray-900"
                  >
                    Subtotal
                  </td>

                  <td
                    class="px-6 py-1 pt-5 whitespace-nowrap text-sm text-gray-900"
                  >
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.subtotal / 100 }}
                  </td>
                </tr>

                <tr
                  v-for="total_tax_amounts in stripeUpcomingInvoice.total_tax_amounts"
                  :key="total_tax_amounts.amount"
                  class="bg-white"
                >
                  <td></td>
                  <td></td>
                  <td
                    class="px-6 py-1 pt-0 whitespace-nowrap text-sm text-gray-400"
                  >
                    {{ total_tax_amounts.tax_rate_expanded.display_name }} ({{
                      total_tax_amounts.tax_rate_expanded.percentage
                    }}%)
                  </td>

                  <td
                    class="px-6 py-1 pt-0 whitespace-nowrap text-sm text-gray-400"
                  >
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.tax / 100 }}
                  </td>
                </tr>

                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    Total
                  </td>

                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.total / 100 }}
                  </td>
                </tr>
                <tr class="bg-white">
                  <td></td>
                  <td></td>
                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    Amount Due
                  </td>

                  <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                    {{ getCurrencySymbol(stripeUpcomingInvoice.currency) }}
                    {{ stripeUpcomingInvoice.amount_due / 100 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-small leading-6 font-medium text-gray-700 py-3">
      Past Stripe Invoices
    </h4>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Number
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Due Date
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Invoice</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoice, invoiceIdx) in stripePaidInvoices"
                  :key="invoice.number"
                  :class="invoiceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {{ getCurrencySymbol(invoice.currency) }}
                    {{ invoice.amount_due / 100 }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ invoice.number }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="mt-3 ml-5 bt-5 px-3 inline-flex text-xs leading-5 rounded-full bg-gray-400 text-gray-100"
                    v-bind:class="{
                      'mt-3 ml-5 bt-5 px-3 text-xs rounded-full bg-green-100 text-gray-800':
                        invoice.status == 'paid',
                      'mt-3 ml-5 px-3 inline-flex text-xs leading-5 rounded-full bg-blue-200 text-blue-600':
                        invoice.status == 'open',
                    }"
                  >
                    {{ convertStatus(invoice.status) }}
                  </td>
                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ convertToDate(invoice.due_date) }}
                  </td>

                  <td
                    v-if="invoice.total != 0 && invoice.status != 'void'"
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a :href="invoice.hosted_invoice_url" target="new">
                      Invoice
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <server-response :response="serverResponse"></server-response>
</template>

<script>
import HomeWidget from '../../components/HomeWidget';
import ServerResponse from '../../components/ServerResponse';
import axios from 'axios';
import moment from 'moment';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';
import LoadingWidget from '../../components/LoadingWidget.vue';
import InlineLoadingWidget from '../../components/InlineLoadingWidget.vue';
import {
  CurrencyEuroIcon,
  DatabaseIcon,
  WifiIcon,
  OfficeBuildingIcon,
  LightBulbIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

export default {
  setup() {
    return {};
  },
  components: {
    HomeWidget,
    ServerResponse,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CurrencyEuroIcon,
    DatabaseIcon,
    WifiIcon,
    OfficeBuildingIcon,
    LightBulbIcon,
    LoadingWidget,
    InlineLoadingWidget,
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  data() {
    return {
      serverURL: process.env.VUE_APP_CLIMECONTROLS_API,
      serverResponse: '',
      revenue_loading: false,
      devices: [],
      gateways: [],
      last30DayDistinctDevEuis: 0,
      last30DayDistinctGatewayEuis: 0,
      previous30DayDistinctDevEuis: 0,
      previous30DayDistinctGatewayEuis: 0,
      stripePaidInvoices: [],
      stripeUpcomingInvoice: '',
      stripeUpcomingInvoiceLineItems: [],
      total_tax_amounts: [],
    };
  },
  methods: {
    getCurrencySymbol(currency) {
      if (currency == 'eur') {
        return '€';
      } else if (currency == 'usd') {
        return '$';
      } else if (currency == 'gbp') {
        return '£';
      }
    },
    convertToDate(timestamp) {
      var day = moment.unix(timestamp); //seconds
      return day.format('MMMM Do YYYY');
    },
    convertStatus(status) {
      if (status == 'open') {
        return 'open';
      } else {
        return status;
      }
    },
    getPaidInvoicesFromStripe(account) {
      this.stripePaidInvoices = [];
      console.log(
        'axios request: ' +
          '/proxy/get-paid-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-paid-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripePaidInvoices = res.data.invoices.data;
          console.log(this.stripePaidInvoices);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    getUpcomingInvoicesFromStripe(account) {
      this.stripeUpcomingInvoice = '';
      this.stripeUpcomingInvoiceLineItems = [];

      console.log(
        'axios request: ' +
          '/proxy/get-upcoming-invoices-from-stripe?account_name=' +
          account
      );

      axios
        .get('/proxy/get-upcoming-invoices-from-stripe?account_name=' + account)
        .then((res) => {
          this.stripeUpcomingInvoice = res.data.invoices;
          this.stripeUpcomingInvoiceLineItems =
            this.stripeUpcomingInvoice.lines.data;

          //If we have taxes, let's get the line items and percentages
          if (this.stripeUpcomingInvoice.total_tax_amounts.length > 0) {
            this.total_tax_amounts =
              this.stripeUpcomingInvoice.total_tax_amounts;

            console.log('this.total_tax_amounts');
            console.log(this.total_tax_amounts);

            //Let's go ahead and actually add the % into this object since Stripe didn't do this for us
            for (let i = 0; i < this.total_tax_amounts.length; i++) {
              const tax_item = this.total_tax_amounts[i];

              axios
                .get('/proxy/get-tax-rate?tax_rate_id=' + tax_item.tax_rate)
                .then((res) => {
                  this.total_tax_amounts[i].tax_rate_expanded =
                    res.data.taxRate;
                })
                .catch((err) => {
                  this.raiseErrorAlert(err);
                });
            }
          }

          console.log('UPCOMING STRIPE INVOICE');
          console.log(this.stripeUpcomingInvoice);
          console.log(this.stripeUpcomingInvoiceLineItems);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    abbreviateNumber(number) {
      const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

      // what tier? (determines SI symbol)
      const tier = (Math.log10(Math.abs(number)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return number;

      // get suffix and determine scale
      const suffix = SI_SYMBOL[tier];
      const scale = Math.pow(10, tier * 3);

      // scale the number
      const scaled = number / scale;

      // format number and add suffix
      return scaled.toFixed(1) + suffix;
    },
    //   loadReport(account) {
    //     this.stats_revenue = [];

    //     const currentAccountSession = account;

    //     //Let's get the revenue numbers
    //     //Assume for now that each device is 3 Euro per month and each gateway is 4 Euro

    //     let queryString =
    //       'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
    //       currentAccountSession +
    //       "' and time between ago(30d) and now()";

    //     this.revenue_loading = true;

    //     axios
    //       .post('/reports/query', { query_string: queryString })
    //       .then((res) => {
    //         this.last30DayDistinctDevEuis = Number(res.data.result[0].COUNT);
    //         queryString =
    //           'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
    //           currentAccountSession +
    //           "' and time between ago(30d) and now()";
    //         axios
    //           .post('/reports/query', { query_string: queryString })
    //           .then((res) => {
    //             this.last30DayDistinctGatewayEuis = Number(
    //               res.data.result[0].COUNT
    //             );

    //             queryString =
    //               'SELECT approx_distinct(dev_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
    //               currentAccountSession +
    //               "' and time between ago(60d) and ago(30d)";
    //             axios
    //               .post('/reports/query', { query_string: queryString })
    //               .then((res) => {
    //                 this.previous30DayDistinctDevEuis = Number(
    //                   res.data.result[0].COUNT
    //                 );

    //                 queryString =
    //                   'SELECT approx_distinct(gateway_eui) AS COUNT FROM "core_timestream_prod"."device_data_v2" WHERE core_account_name = \'' +
    //                   currentAccountSession +
    //                   "' and time between ago(60d) and ago(30d)";
    //                 axios
    //                   .post('/reports/query', { query_string: queryString })
    //                   .then((res) => {
    //                     this.previous30DayDistinctGatewayEuis = Number(
    //                       res.data.result[0].COUNT
    //                     );

    //                     let last30DayRevenue =
    //                       this.last30DayDistinctDevEuis * 3 +
    //                       this.last30DayDistinctGatewayEuis * 4;

    //                     let previous30DayRevenue =
    //                       this.previous30DayDistinctDevEuis * 3 +
    //                       this.previous30DayDistinctGatewayEuis * 4;

    //                     console.log(
    //                       'previous30DayRevenue: ' + previous30DayRevenue
    //                     );

    //                     let increase = last30DayRevenue - previous30DayRevenue;
    //                     let changeType = 'increase';
    //                     if (increase < 0) {
    //                       changeType = 'decrease';
    //                     }

    //                     this.stats_revenue.push({
    //                       name: '30 Day Runrate',
    //                       stat: '€' + last30DayRevenue,
    //                       changeType: changeType,
    //                       change: '€' + increase,
    //                       icon: CurrencyEuroIcon,
    //                     });

    //                     this.revenue_loading = false;
    //                   })
    //                   .catch((err) => {
    //                     this.raiseErrorAlert(err);
    //                     this.serverResponse = JSON.stringify(
    //                       err.response,
    //                       null,
    //                       2
    //                     );
    //                     this.revenue_loading = false;
    //                   });
    //               })
    //               .catch((err) => {
    //                 this.raiseErrorAlert(err);
    //                 this.serverResponse = JSON.stringify(err.response, null, 2);
    //                 this.revenue_loading = false;
    //               });
    //           })
    //           .catch((err) => {
    //             this.raiseErrorAlert(err);
    //             this.serverResponse = JSON.stringify(err.response, null, 2);
    //             this.revenue_loading = false;
    //           });
    //       })
    //       .catch((err) => {
    //         this.raiseErrorAlert(err);
    //         this.serverResponse = JSON.stringify(err.response, null, 2);
    //         this.revenue_loading = false;
    //       });
    //   },
  },
  mounted() {
    this.serverURL = process.env.VUE_APP_CLIMECONTROLS_API;
    console.log('serverURL : ' + this.serverURL);
  },
  created() {
    console.log('PlatformSummaryReport.created() called');
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        console.log('value changes detected - reloading report');
        // _this.loadReport(_this.$store.state.clime_account.name);
        _this.getPaidInvoicesFromStripe(_this.$store.state.clime_account.name);
        _this.getUpcomingInvoicesFromStripe(
          _this.$store.state.clime_account.name
        );
      }
    );
    const currentAccountSession = this.$store.state.clime_account.name;
    console.log(
      'this.$store.state.clime_account.name: ' +
        this.$store.state.clime_account.name
    );
    // this.loadReport(currentAccountSession);
    this.getPaidInvoicesFromStripe(currentAccountSession);
    this.getUpcomingInvoicesFromStripe(currentAccountSession);
  },
};
</script>
