<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="overflow-hidden bg-white drop-shadow-2xl sm:rounded-lg sm:p-10 p-5"
  >
    <div>
      <div class="mt-0">
        <dl class="">
          <div
            v-if="capability == 'electricity' || capability == 'gas'"
            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4"
          >
            <dt
              v-if="capability == 'electricity'"
              class="text-xl font-medium text-gray-500 pt-5"
            >
              Meter Reading (kWh)
            </dt>
            <dt
              v-else-if="capability == 'gas'"
              class="text-xl font-medium text-gray-500 pt-5"
            >
              Meter Reading (M<sup>3</sup>)
            </dt>
            <dd>
              <!-- {{
                Math.round(
                  device.electricity_base_meter_reading +
                    (device.electricity / 1000) * meter_ratio
                )
              }} -->
              <odometer-display
                :number="meter_digits"
                :key="meter_digits"
              ></odometer-display>
            </dd>
          </div>

          <div class="py-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:py-5">
            <div v-if="chart">
              <div class="filter shadow mt-2 mb-2 border bg-white rounded-md">
                <apexchart
                  width="100%"
                  height="300"
                  :options="chart.options"
                  :series="chart.series"
                ></apexchart>
              </div>
            </div>
          </div>
          <div v-if="capability == 'electricity'" class="pb-20">
            <div class="text-sm font-medium text-gray-500">Chart notes</div>
            <div class="text-sm font-small text-gray-400">
              Electricity readings are taken every 20 mins. Each value
              represents the average kWh of energy being consumed during that 20
              min interval. For example, a value of 4.27 on a particular
              timestamp means there was an average of 4.27 kWh of energy being
              consumed during the previous 20 minutes. If this amount of energy
              consumption continued for 1 hour, that would mean a total of 4.27
              kW of energy would have been used in that hour. This allows you to
              view spikes in the data that last less than 1 hour
            </div>
          </div>
          <div v-else-if="capability == 'gas'" class="pb-20">
            <div class="text-sm font-medium text-gray-500">Chart notes</div>
            <div class="text-sm font-small text-gray-400">
              Gas readings are sent every 2 hours. Each value represents the
              amount of Gas (in D<sup>3</sup>) consumed in the 2 hours
              preceeding the timestamp
            </div>
          </div>
        </dl>
      </div>
    </div>

    <div
      v-if="capability == 'gas' || capability == 'electricity'"
      class="px-4 sm:px-6 lg:px-8"
    >
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">
            Previous Meter Readings (Placeholder only)
          </h1>
          <p class="mt-2 text-sm text-gray-700">Readings by month</p>
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Month
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Meter Reading
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      v-if="capability == 'electricity'"
                    >
                      Energy Usage (kWh)
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      v-if="capability == 'gas'"
                    >
                      Energy Usage (D<sup>3</sup>)
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="capability == 'electricity'"
                  class="divide-y divide-gray-200 bg-white"
                >
                  <tr
                    v-for="reading in electricity_monthly_reading"
                    :key="reading.month"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      {{ reading.month }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ reading.meter_reading }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ reading.energy_usage }}
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="capability == 'gas'"
                  class="divide-y divide-gray-200 bg-white"
                >
                  <tr
                    v-for="reading in gas_monthly_reading"
                    :key="reading.month"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      {{ reading.month }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ reading.meter_reading }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ reading.energy_usage }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RssIcon } from '@heroicons/vue/outline';
import { PaperClipIcon } from '@heroicons/vue/outline';
import axios from 'axios';
import OdometerDisplay from './OdometerDisplay.vue';

const electricity_monthly_reading = [
  {
    month: 'Sept 1st 2022',
    meter_reading: '136,321',
    energy_usage: '231',
  },
  {
    month: 'Aug 1st 2022',
    meter_reading: '136,109',
    energy_usage: '212',
  },
  {
    month: 'July 1st 2022',
    meter_reading: '135,919',
    energy_usage: '190',
  },
  {
    month: 'Jun 1st 2022',
    meter_reading: '135,718',
    energy_usage: '201',
  },
  {
    month: 'May 1st 2022',
    meter_reading: '135,522',
    energy_usage: '196',
  },
  {
    month: 'April 1st 2022',
    meter_reading: '135301',
    energy_usage: '221',
  },
];
const gas_monthly_reading = [
  {
    month: 'Sept 1st 2022',
    meter_reading: '248,600',
    energy_usage: '1400',
  },
  {
    month: 'Aug 1st 2022',
    meter_reading: '247,440',
    energy_usage: '1160',
  },
  {
    month: 'July 1st 2022',
    meter_reading: '246,520',
    energy_usage: '920',
  },
  {
    month: 'Jun 1st 2022',
    meter_reading: '244,800',
    energy_usage: '1720',
  },
  {
    month: 'May 1st 2022',
    meter_reading: '243,120',
    energy_usage: '1680',
  },
  {
    month: 'April 1st 2022',
    meter_reading: '241,480',
    energy_usage: '1640',
  },
];

export default {
  props: {
    _id: { required: true, type: String },
    core_device_id: { required: true, type: String },
    capability: { required: true, type: String },
  },
  setup() {
    return {
      electricity_monthly_reading,
      gas_monthly_reading,
    };
  },
  created() {
    // console.log('created in meter panel called!');
    this.getDeviceObject();
    this.loadGraph('7d', this.capability);
  },
  components: {
    RssIcon,
    PaperClipIcon,
    OdometerDisplay,
  },
  data() {
    return {
      chart: null,
      device: {},
      // meter_ratio: 1,
      meter_digits: '',
      impressions_ratio: 1000,
    };
  },

  methods: {
    getDeviceObject() {
      axios
        .get('/devices/' + this._id)
        .then((res) => {
          // console.log(res.data);
          this.device = res.data.data;
          let base_reading_array = this.device.base_meter_reading_array;
          let last_reading = 0;
          this.impressions_ratio = this.device.imp_per_unit;

          if (this.device.capabilities.includes('electricity')) {
            last_reading = this.device.electricity;
          } else if (this.device.capabilities.includes('gas')) {
            last_reading = this.device.gas;
          }

          if (base_reading_array != null) {
            base_reading_array.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.date) - new Date(a.date);
            });

            this.meter_digits = Math.round(
              parseInt(base_reading_array[0].base_reading) +
                last_reading / this.impressions_ratio
            ).toString();

            console.log('last_reading: ' + last_reading);
          }
        })
        .catch();
    },
    loadGraph(timeframe, capability) {
      console.log('loadGraph called!');
      console.log('timeframe: [' + timeframe + ']');

      let series_type = null;
      if (capability == 'electricity') {
        series_type = 'electricity_series';
      } else if (capability == 'gas') {
        series_type = 'gas_series';
      }

      console.log(
        'url : ' +
          '/reports/device?measure_name=' +
          series_type +
          '&core_device_id=' +
          this.core_device_id +
          '&timeframe=' +
          timeframe
      );

      axios
        .get(
          '/reports/device?measure_name=' +
            series_type +
            '&measure_name=' +
            series_type +
            '&core_device_id=' +
            this.core_device_id +
            '&timeframe=' +
            timeframe +
            '&timeframe_comparison=true'
        )
        .then((res) => {
          // console.log('res.data.series:');
          // console.log(res.data.series);

          // console.log('res.data.series[0]:');
          // console.log(res.data.series[0]);

          // console.log('res.data.series[0].data:');
          // console.log(res.data.series[0].data);

          let fine_grain_graph = false;
          if (timeframe == '1h') {
            fine_grain_graph = true;
          }

          //This will now contain 2 sets of series_data
          //one for this week and one for last week

          let multiple_series_data = [];

          // console.log('number of series in the dataset: ');
          //console.log(res.data.series.length);

          for (let i = 0; i < res.data.series.length; i++) {
            let single_series = {
              name: 'timeframe -' + i,
              data: [],
            };

            if (i == 0) {
              single_series.name = 'this week';
            } else if (i == 1) {
              single_series.name = 'last week';
            }

            let new_series_data = [];

            for (let data of res.data.series[i].data) {
              // let max_value_in_timeframe = 0;
              let running_total_in_timeframe = 1;
              let last_timestamp = null;
              let i = 0;
              // let data_point = {};

              for (let expanded_row of data.y) {
                i++;

                if (fine_grain_graph) {
                  // if (expanded_row.value != 'NaN') {
                  //   new_series_data.push({
                  //     x: expanded_row.timestamp,
                  //     y: expanded_row.value * this.meter_ratio,
                  //   });
                  // }
                  if (expanded_row.value != 'NaN') {
                    new_series_data.push({
                      x: expanded_row.timestamp,
                      y: expanded_row.value / this.impressions_ratio,
                    });
                  }
                } else {
                  if (expanded_row.value != 'NaN') {
                    running_total_in_timeframe =
                      running_total_in_timeframe + expanded_row.value;
                    last_timestamp = expanded_row.timestamp;
                  }
                }
              }
              // console.log('running_total_in_timeframe: ');
              // console.log(running_total_in_timeframe);

              if (!fine_grain_graph) {
                //For electricity, we take the average of all readings
                //For Gas, we take the addition of the readings

                let final_reading = null;

                if (capability == 'electricity') {
                  final_reading = Math.round(running_total_in_timeframe / i);
                  //console.log('final_reading - electricity: ' + final_reading);
                } else if (capability == 'gas') {
                  final_reading = running_total_in_timeframe.toFixed(2);
                  //console.log('final_reading - gas: ' + final_reading);
                }

                // if (final_reading) {
                //   if (capability == 'electricity') {
                //     new_series_data.push({
                //       x: last_timestamp,
                //       y: ((final_reading * this.meter_ratio) / 1000).toFixed(2),
                //     });
                //   } else if (capability == 'gas') {
                //     new_series_data.push({
                //       x: last_timestamp,
                //       y: (final_reading / this.impressions_ratio).toFixed(2),
                //     });
                //   }
                // }
                if (final_reading) {
                  new_series_data.push({
                    x: last_timestamp,
                    y: (final_reading / this.impressions_ratio).toFixed(2),
                  });
                }
              }
            }
            single_series.data = new_series_data;
            multiple_series_data.push(single_series);
          }
          //  console.log(multiple_series_data);
          //  console.log('=====finished - ready to draw graph======');

          this.chart = {
            core_device_id: 'core_device_id',
            options: {
              theme: {
                palette: 'palette2',
              },

              chart: {
                stacked: false,
                type: 'area',
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: 'zoom',
                },
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 1,
                dashArray: 0,
              },
              title: {
                text: this.device.core_device_id,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238',
                },
              },
              subtitle: {
                text: this.device.description,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 20,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'normal',
                  fontFamily: undefined,
                  color: '#9699a2',
                },
              },
              dataLabels: {
                enabled: false,
              },

              tooltip: {
                enabled: true,
                x: {
                  show: true,
                  format: 'dd MMM HH:mm',
                },
              },
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: [],
                },
                autoSelected: 'zoom',
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  format: 'ddd',
                },
              },
            },
            //series: res.data.series,
            series: multiple_series_data,
          };

          // for (let element of set.series) {
          //   // console.log('series:' + element.name);
          //   if (element.name == 'temperature') {
          //     element.type = 'area';
          //   }
          // }
          // this.dataset.push(set);
          // console.log(set);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //this.loading = false;
  },
};
</script>
