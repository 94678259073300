<template>
  <div class="relative">
    <GoogleMap
      :api-key="googleMapsAPIKey"
      :style="{ width: '100%', height: '600px' }"
      :center="center"
      :zoom="16"
    >
      <Polyline :options="gatewayRoute" />
    </GoogleMap>
  </div>
  <server-response :response="serverResponse"></server-response>
</template>

<script>
import ServerResponse from '../../components/ServerResponse';
import axios from 'axios';
import { GoogleMap, Marker, Polyline } from 'vue3-google-map';
import * as dateMath from 'date-arithmetic';
import { CheckCircleIcon, WifiIcon } from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';
import encodeurl from 'encodeurl';

export default {
  setup() {},
  components: {
    ServerResponse,
    GoogleMap,
    Marker,
    Polyline,
    CheckCircleIcon,
    XIcon,
    WifiIcon,
  },
  data() {
    return {
      serverURL: process.env.VUE_APP_CLIMECONTOLS_API,
      serverResponse: '',
      center: { lat: 0, lng: 0 },
      center_is_set: false,
      gatewayRouteCoordinates: [],
      gatewayRoute: {},
      gateways: [],
      googleMapsAPIKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      clickedGateway: null,
      interval: null,
    };
  },
  methods: {
    loadPage() {
      const currentAccountSession = this.$store.state.clime_account.name;
      const currentProjectSession = this.$store.state.clime_project.name;

      console.log('polling gateways');
      axios
        .get(
          '/gateways?core_account_name=' +
            currentAccountSession +
            '&core_project_name=' +
            currentProjectSession
        )
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          console.log('Number of gateways: ' + res.data.count);
          this.gateways = res.data.data;

          for (let gateway of this.gateways) {
            let query_string = `SELECT * FROM "core_timestream_prod"."gateway_locations_v1" WHERE gateway_id = '${gateway.core_gateway_id}' and time between ago(5d) and now() ORDER BY time ASC`;
            axios
              .post('/reports/query', { query_string: query_string })
              .then((res) => {
                //console.log(res);
                let coordinates = [];
                for (let result of res.data.result) {
                  coordinates.push(result.latitude + ',' + result.longitude);
                }
                let map_url = encodeurl(coordinates.join('|'));
                //console.log(map_url);
                this.loadMap(map_url);
              })
              .catch();
          }
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        })
        .finally(() => {
          console.log('this.gateways.length: ' + this.gateways.length);
          for (let i = 0; i < this.gateways.length; i++) {
            this.gateways[i].image = require('@/assets/red-marker.png');
          }
        });
    },

    loadMap(map_url) {
      let base_url = 'https://roads.googleapis.com/v1/snapToRoads?path=';
      axios
        .get(
          base_url +
            map_url +
            '&interpolate=true&key=' +
            process.env.VUE_APP_GOOGLE_MAPS_API_KEY
        )
        .then((res) => {
          console.log(JSON.stringify(res.data));

          for (let point of res.data.snappedPoints) {
            this.gatewayRouteCoordinates.push({
              lat: point.location.latitude,
              lng: point.location.longitude,
            });
            if (!this.center_is_set) {
              this.center = {
                lat: point.location.latitude,
                lng: point.location.longitude,
              };
              this.center_is_set = true;
            }
          }

          this.gatewayRoute = {
            path: this.gatewayRouteCoordinates,
            geodesic: true,
            strokeColor: '#3b82f6',
            strokeOpacity: 0.75,
            strokeWeight: 5,
          };
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
  },

  mounted() {
    this.serverURL = process.env.VUE_APP_CLIMECONTROLS_API;
    console.log('serverURL : ' + this.serverURL);
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
    this.interval = setInterval(() => {
      this.loadPage();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>
