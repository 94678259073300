<template>
  <div class="grid grid-cols-2 bg-white border border-transparent"></div>

  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <router-link :to="'/account-settings/projects/new'">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Project
        </button>
      </router-link>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Project Name
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateways
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Devices
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(project, projectIdx) in projects"
                  :key="project.core_project_name"
                  :class="projectIdx % 2 === 0 ? 'bg-white' : 'bg-white'"
                >
                  <td
                    class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex"
                  >
                    {{ project.core_project_name }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div>
                      {{ project.gateway_count }}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div>
                      {{ project.device_count }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <router-link
                      class="text-indigo-600 hover:text-indigo-900"
                      :to="'/account-settings/projects/' + project._id"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../../components/ServerResponse';
import AlertBanner from '../../components/AlertBanner';
import { SearchIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    SearchIcon,
    XIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  data() {
    return {
      projects: [],
      serverResponse: '',
      role: '',
      core_account_name: null,
    };
  },
  methods: {
    getGatewayCount(project) {
      console.log('getGatewayCount(project) called');
      axios
        .get(
          'gateways?core_account_name=' +
            this.core_account_name +
            '&core_project_name=' +
            project.core_project_name
        )
        .then((res) => {
          project.gateway_count = res.data.count;
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
    getDeviceCount(project) {
      axios
        .get(
          'devices?core_account_name=' +
            this.core_account_name +
            '&core_project_name=' +
            project.core_project_name
        )
        .then((res) => {
          //We also include all devices that are currently not with a project so we need to strip these out
          let project_devices = [];
          for (let returned_project of res.data.data) {
            if (
              returned_project.core_project_name == project.core_project_name
            ) {
              project_devices.push(returned_project);
            }
            // project.device_count = res.data.count;
          }
          project.device_count = project_devices.length;
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    loadPage() {
      this.core_account_name = this.$store.state.clime_account.name;
      this.role = this.$store.state.account.role;
      console.log('role: ' + this.role);

      axios
        .get('/accounts?account_name=' + this.core_account_name)
        .then((res) => {
          console.log(res.data);

          this.projects = res.data.data[0].core_projects;
          for (let project of this.projects) {
            this.getGatewayCount(project);
            this.getDeviceCount(project);
          }
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
  },

  beforeCreate() {
    let _this = this;
    this.$store.watch(
      () => this.$store.state.clime_account.name,
      function () {
        _this.loadPage();
      }
    );
    this.$store.watch(
      () => this.$store.state.clime_project.name,
      function () {
        _this.loadPage();
      }
    );
  },

  created() {
    this.loadPage();
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
